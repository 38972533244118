











































































import Vue from "vue";
import { mapGetters } from "vuex";
import { AppointmentWeekTime, Day } from "@/components/modules/types/Calendar";
import moment from "moment";
import i18n from "@/i18n/index";
import CalendarHelper from "@/helpers/CalendarHelper";
const calendarHelper = new CalendarHelper();

interface CalendarData {
  currentDate: string;
}

interface ExtendedCalendarData extends CalendarData {
  temp: number;
  currentDay: string;
  daysToDisplay: number;
}

export default Vue.extend({
  props: {
    appointments: calendarHelper.getAppointmentsPropValidator(),
    selectedWeek: {
      type: String,
      default: function () {
        return moment().format();
      },
    },
    selectedAppointmentId: {
      type: String,
      default: "",
    },
  },
  data(): ExtendedCalendarData {
    return {
      currentDate: moment().format(),
      temp: 0,
      currentDay: calendarHelper.getFirstAppointment(this.appointments),
      daysToDisplay: 7,
    };
  },
  methods: {
    displayGrid: function (i: number) {
      return (
        this.getOverlappingTimes * (i + 1) - (this.getOverlappingTimes - 2)
      );
    },
    columnStart: function (actualPosition: number, alternateLane: number) {
      this.temp = this.getOverlappingTimes;

      return (
        actualPosition * this.getOverlappingTimes +
        2 +
        alternateLane -
        this.temp
      );
    },
    resetValues: function () {
      this.daysToDisplay = calendarHelper.calendarDisplayDays(
        this.getOverlappingTimes
      );
    },
  },
  mounted() {
    // calculate grid in dependency to timewindows for a day
    const calendargrid = document.getElementsByClassName(
      "calendar-grid"
    )[0] as HTMLElement;
    calendargrid.style.gridTemplateColumns = `40px repeat(${
      this.daysToDisplay * this.getOverlappingTimes
    }, minmax(0, 1fr))`;

    //set background slot filler
    for (let i = 0; i < this.daysToDisplay; i++) {
      //14 because of 14 time zones
      for (let j = 14 * i; j < 14 * i + 14; j++) {
        const calendarFiller = document.getElementsByClassName(
          "calendar-filler"
        )[j] as HTMLElement;
        calendarFiller.style.gridArea = `${j - 14 * i + 2} / ${
          this.getOverlappingTimes * (i + 1) - (this.getOverlappingTimes - 2)
        } / span 1 / span ${this.getOverlappingTimes}`;
      }
    }
  },
  computed: {
    ...mapGetters(["getOverlappingTimes"]),
    weeksToDisplay(): Day[] {
      this.resetValues();

      let localeValue = i18n.locale;

      if(localeValue == "cz"){
        localeValue = "cs";
      }

      moment.locale(localeValue);
      const today = moment();

      const firstDayOfThisWeek = moment(this.selectedWeek).clone();
      if (!firstDayOfThisWeek) return [];

      const days = [];
      const tmpDaysToDisplay = calendarHelper.calendarDisplayDays(
        this.getOverlappingTimes
      );

      for (let i = 0; i < tmpDaysToDisplay; i++) {
        const day = firstDayOfThisWeek.clone().add(i, "days");

        days.push({
          dayOfMonth: day.date(),
          isToday: day.isSame(today, "day"),
          appointments: [],
          isRecommended: false,
          date: calendarHelper.getFormattedDate(day),
          weekDay: day.format("dd")[0].toUpperCase() + day.format("dd")[1],
        });
      }
      return days;
    },
    appointmentsOfWeek(): AppointmentWeekTime[] {
      const appointmentsWithinWeek = calendarHelper.getMaxAlternateLane(
        this.appointments,
        this.getOverlappingTimes,
        this.daysToDisplay,
        this.selectedWeek
      );
      return appointmentsWithinWeek;
    },
  },
});
