var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-weektime",class:{ 'has-selected': _vm.selectedAppointmentId }},[_c('div',{staticClass:"calendar-container"},[_c('div',{staticClass:"calendar-grid"},[_vm._l((_vm.weeksToDisplay),function(weekday,i){return _c('div',{key:'w' + weekday.dayOfMonth,staticClass:"calendar-weekday",style:(("grid-area: 1 / " + (_vm.displayGrid(
          i
        )) + " / auto / span " + _vm.getOverlappingTimes + ";"))},[_c('div',{staticClass:"calendar-weekday"},[_vm._v(_vm._s(weekday.weekDay))]),_c('div',{staticClass:"calendar-weekday-number",class:{ 'is-today': weekday.isToday }},[_vm._v(" "+_vm._s(weekday.dayOfMonth)+" ")])])}),_vm._l((15),function(n){return _c('div',{key:'t' + n,staticClass:"calendar-timeslot",style:(("grid-area: " + n + " / 1 / auto / auto;"))},[_vm._v(" "+_vm._s(n + 6)+" ")])}),_vm._l((14 * _vm.daysToDisplay),function(n){return _c('div',{key:("f-" + n),staticClass:"calendar-filler"})}),_vm._l((_vm.appointmentsOfWeek),function(a){return _c('div',{key:'a' + a.id,staticClass:"calendar-appointment",class:{
          'is-recommendation': a.isRecommendation,
          'is-selected': a.id === _vm.selectedAppointmentId,
        },style:(("\n                  grid-column-start: " + (_vm.columnStart(
                    a.actualPosition,
                    a.alternateLane
                  )) + ";\n                  grid-row-start: " + (a.startHour - 5) + ";\n                  grid-row-end: " + (a.endHour - 5) + ";\n                  ")),attrs:{"data-dayofweek":a.dayOfWeek},on:{"click":function($event){return _vm.$emit('change', a)}}},[_c('div',{staticClass:"calendar-appointment-timewindow",domProps:{"innerHTML":_vm._s(a.timeDisplay)}},[_c('br')]),(_vm.$i18n.locale !== 'pl')?_c('div',{staticClass:"calendar-appointment-price"},[_vm._v(" "+_vm._s(_vm._f("formatAmount")(a.price,a.currency))+" ")]):_vm._e(),_c('div',{staticClass:"calendar-appointment-checked"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2m-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"}})])])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }